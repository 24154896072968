// src/components/BatteryProgressBar.jsx

import PropTypes from 'prop-types';
import React from 'react';
import { FaBatteryEmpty, FaBatteryFull, FaBatteryHalf } from 'react-icons/fa';
import './BatteryProgressBar.css';

const BatteryProgressBar = ({ percentage, size }) => {
  const clampedPercentage = Math.max(0, Math.min(percentage, 100));

  // Determine the color class and icon based on percentage
  let colorClass = 'high';
  let BatteryIcon = FaBatteryFull;
  if (clampedPercentage <= 20) {
    colorClass = 'low';
    BatteryIcon = FaBatteryEmpty;
  } else if (clampedPercentage <= 60) {
    colorClass = 'medium';
    BatteryIcon = FaBatteryHalf;
  }

  return (
    <div
      className={`battery-container ${size}`}
      aria-label={`Progress at ${clampedPercentage} percent`}
      role="progressbar"
      aria-valuenow={clampedPercentage}
      aria-valuemin="0"
      aria-valuemax="100"
    >
    <BatteryIcon className="battery-icon" />
      
    </div>
  );
};

BatteryProgressBar.propTypes = {
  percentage: PropTypes.number.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};


export default BatteryProgressBar;
