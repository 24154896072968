import { motion } from "framer-motion";
import React, { useState } from "react";

function ScorePredictor() {
  const [formData, setFormData] = useState({
    gender: "male", // Default value for gender
    race_ethnicity: "group A", // Default value for race/ethnicity
    parental_level_of_education: "high school", // Default value for education
    lunch: "standard", // Default value for lunch type
    test_preparation_course: "none", // Default value for test prep course
    writing_score: 50,
    reading_score: 50,
  });

  const [prediction, setPrediction] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSegmentedChange = (field, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleSliderChange = (field, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const res = await fetch(
      "https://sinaintech.dev/api/projects/score-predictor",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      }
    );

    const data = await res.json();
    setPrediction(data);
    setLoading(false);
  };

  return (
    <motion.section
      className="min-h-screen flex items-center justify-center bg-gradient-to-r from-gray-900 to-gray-800 p-8 text-white"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
    >
      <div className="max-w-4xl w-full bg-gray-800 rounded-lg shadow-lg p-8">
        <h2 className="text-4xl font-bold text-center mb-6">Score Predictor</h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Gender (Segmented Button) */}
          <div className="flex flex-col">
            <label className="text-lg font-bold mb-2">Gender</label>
            <div className="flex space-x-4">
              <button
                type="button"
                onClick={() => handleSegmentedChange("gender", "male")}
                className={`px-4 py-2 rounded-lg border-2 ${
                  formData.gender === "male"
                    ? "bg-blue-600 border-blue-600 text-white"
                    : "border-gray-600 text-gray-400"
                }`}
              >
                Male
              </button>
              <button
                type="button"
                onClick={() => handleSegmentedChange("gender", "female")}
                className={`px-4 py-2 rounded-lg border-2 ${
                  formData.gender === "female"
                    ? "bg-blue-600 border-blue-600 text-white"
                    : "border-gray-600 text-gray-400"
                }`}
              >
                Female
              </button>
            </div>
          </div>

          {/* Race/Ethnicity (Segmented Button) */}
          <div className="flex flex-col">
            <label className="text-lg font-bold mb-2">Race/Ethnicity</label>
            <div className="flex space-x-2 flex-wrap">
              {["group A", "group B", "group C", "group D", "group E"].map(
                (group) => (
                  <button
                    key={group}
                    type="button"
                    onClick={() =>
                      handleSegmentedChange("race_ethnicity", group)
                    }
                    className={`px-4 py-2 mb-2 rounded-lg border-2 ${
                      formData.race_ethnicity === group
                        ? "bg-green-600 border-green-600 text-white"
                        : "border-gray-600 text-gray-400"
                    }`}
                  >
                    {group}
                  </button>
                )
              )}
            </div>
          </div>

          {/* Parental Level of Education (Segmented Button) */}
          <div className="flex flex-col">
            <label className="text-lg font-bold mb-2">
              Parental Level of Education
            </label>
            <div className="flex space-x-2 flex-wrap">
              {[
                "associate's degree",
                "bachelor's degree",
                "high school",
                "master's degree",
                "some college",
                "some high school",
              ].map((degree) => (
                <button
                  key={degree}
                  type="button"
                  onClick={() =>
                    handleSegmentedChange("parental_level_of_education", degree)
                  }
                  className={`px-4 py-2 mb-2 rounded-lg border-2 ${
                    formData.parental_level_of_education === degree
                      ? "bg-yellow-600 border-yellow-600 text-white"
                      : "border-gray-600 text-gray-400"
                  }`}
                >
                  {degree}
                </button>
              ))}
            </div>
          </div>

          {/* Lunch Type (Segmented Button) */}
          <div className="flex flex-col">
            <label className="text-lg font-bold mb-2">Lunch Type</label>
            <div className="flex space-x-4">
              <button
                type="button"
                onClick={() => handleSegmentedChange("lunch", "standard")}
                className={`px-4 py-2 rounded-lg border-2 ${
                  formData.lunch === "standard"
                    ? "bg-blue-600 border-blue-600 text-white"
                    : "border-gray-600 text-gray-400"
                }`}
              >
                Standard
              </button>
              <button
                type="button"
                onClick={() => handleSegmentedChange("lunch", "free/reduced")}
                className={`px-4 py-2 rounded-lg border-2 ${
                  formData.lunch === "free/reduced"
                    ? "bg-blue-600 border-blue-600 text-white"
                    : "border-gray-600 text-gray-400"
                }`}
              >
                Free/Reduced
              </button>
            </div>
          </div>

          {/* Test Preparation Course (Segmented Button) */}
          <div className="flex flex-col">
            <label className="text-lg font-bold mb-2">Test Prep Course</label>
            <div className="flex space-x-4">
              <button
                type="button"
                onClick={() =>
                  handleSegmentedChange("test_preparation_course", "completed")
                }
                className={`px-4 py-2 rounded-lg border-2 ${
                  formData.test_preparation_course === "completed"
                    ? "bg-green-600 border-green-600 text-white"
                    : "border-gray-600 text-gray-400"
                }`}
              >
                Completed
              </button>
              <button
                type="button"
                onClick={() =>
                  handleSegmentedChange("test_preparation_course", "none")
                }
                className={`px-4 py-2 rounded-lg border-2 ${
                  formData.test_preparation_course === "none"
                    ? "bg-green-600 border-green-600 text-white"
                    : "border-gray-600 text-gray-400"
                }`}
              >
                None
              </button>
            </div>
          </div>

          {/* Writing Score */}
          <div className="flex flex-col">
            <label
              htmlFor="writing_score"
              className="text-lg font-bold flex items-center"
            >
              Writing Score
            </label>
            <input
              type="range"
              name="writing_score"
              min="0"
              max="100"
              value={formData.writing_score}
              onChange={(e) =>
                handleSliderChange("writing_score", e.target.value)
              }
              className="w-full"
            />
            <p className="text-center text-gray-400 mt-2">
              🌟 Writing Score: {formData.writing_score} 🌟
            </p>
          </div>

          {/* Reading Score */}
          <div className="flex flex-col">
            <label htmlFor="reading_score" className="text-lg font-bold">
              Reading Score
            </label>
            <input
              type="range"
              name="reading_score"
              min="0"
              max="100"
              value={formData.reading_score}
              onChange={(e) =>
                handleSliderChange("reading_score", e.target.value)
              }
              className="w-full"
            />
            <p className="text-center text-gray-400 mt-2">
              🌟 Reading Score: {formData.reading_score} 🌟
            </p>
          </div>

          {/* Submit Button */}
          <motion.button
            type="submit"
            className="w-full bg-indigo-600 text-white py-4 rounded-lg font-semibold text-lg mt-4 transition duration-200 hover:bg-indigo-700 shadow-lg"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            {loading ? "Predicting..." : "Predict"}
          </motion.button>
        </form>

        {/* Loading and Prediction Response */}
        {loading && (
          <div className="text-xl text-center mt-6">Loading prediction...</div>
        )}
        {prediction && (
          <div className="mt-8 text-center">
            <h3 className="text-2xl font-semibold">
              Predicted Score:{" "}
              <span className="text-green-400">{prediction.results}</span>
            </h3>
          </div>
        )}
      </div>
    </motion.section>
  );
}

export default ScorePredictor;
