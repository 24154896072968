// src/components/JobItem.jsx

import React from "react";

function JobItem({ job, onPreview }) {
  const maxDescriptionLength = 300; // Adjust as needed

  const truncateDescription = (description) => {
    if (description.length <= maxDescriptionLength) return description;
    return description.slice(0, maxDescriptionLength) + "...";
  };

  return (
    <div className="flex flex-col p-4 mb-4 rounded-lg bg-gray-700 dark:bg-gray-800 shadow-lg">
      <div className="flex items-start">
        <div className="flex-1">
          <div className="flex justify-between items-center">
            <a
              href={job.link}
              target="_blank"
              rel="noopener noreferrer"
              className="text-lg font-semibold text-blue-400 break-words"
            >
              {job.title}
            </a>
            <span className="text-sm text-gray-400 ml-2">{job.platform}</span>
          </div>
          <p className="text-sm text-gray-400 mt-1 break-words">
            {job.company}
          </p>
        </div>
      </div>
      
      {/* Job Description */}
      <div className="mt-2">
        <p className="text-sm text-gray-300">
          {truncateDescription(job.description)}
        </p>
      </div>
      
      {/* Action Buttons */}
      <div className="mt-4 flex space-x-2">
        <button
          onClick={() => onPreview(job)}
          className="bg-green-500 hover:bg-green-700 text-white px-4 py-2 rounded"
        >
          Preview
        </button>
        {/* Add more buttons if needed */}
      </div>
    </div>
  );
}

export default JobItem;
