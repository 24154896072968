import { motion } from "framer-motion";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { TypeAnimation } from "react-type-animation";

function Home({ setShowNavbar, setActiveTab }) {
  const [showNavbarLocal, setShowNavbarLocal] = useState(false);
  const navigate = useNavigate(); // Initialize the navigate hook
  const profileImage = `${process.env.PUBLIC_URL}/sina.png`;

  const handleClick = (page) => {
    if (page === "home") return; // Do nothing when clicking 'Home' in the middle field
    setShowNavbar(true); // Shows the navbar on top for other tabs
    setShowNavbarLocal(true); // Local state to hide middle content
    setActiveTab(page); // Set the active tab for underline
    navigate(`/${page}`); // Navigate to the corresponding page programmatically
  };

  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-transparent text-white relative">
      {" "}
      {/* Name and Typing Animation */}
      <motion.img
        src={profileImage}
        alt="Profile"
        className="w-40 h-40 object-cover rounded-full mb-6"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      />
      <motion.div
        className="text-center z-2"
        initial={{ y: 0, opacity: 1 }}
        animate={
          showNavbarLocal ? { y: -200, opacity: 0 } : { y: 0, opacity: 1 }
        }
        transition={{ duration: 0.8 }}
      >
        <h1 className="text-5xl font-bold mb-4">Sina Behdadkia</h1>
        <div className="flex text-2xl text-white">
          <span className="mr-2">I'm</span>
          <TypeAnimation
            sequence={[
              "a Coder",
              2000,
              "a Data Enthusiast",
              2000,
              "an AI Enthusiast",
              2000,
              "a Software Developer",
              2000,
            ]}
            speed={50}
            wrapper="span"
            repeat={Infinity}
            className="font-bold text-green-400"
          />
        </div>
      </motion.div>
      {/* Navigation Links in the Middle */}
      {!showNavbarLocal && (
        <motion.div
          className="flex space-x-8 mt-8 text-lg font-semibold z-2"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 1 }}
        >
          <button
            onClick={() => handleClick("home")}
            className="hover:text-green-400"
          >
            Home
          </button>
          <button
            onClick={() => handleClick("resume")}
            className="hover:text-green-400"
          >
            Résumé
          </button>
          <button
            onClick={() => handleClick("projects")}
            className="hover:text-green-400"
          >
            Projects
          </button>
          <button
            onClick={() => handleClick("contact")}
            className="hover:text-green-400"
          >
            Contact
          </button>
        </motion.div>
      )}
    </div>
  );
}

export default Home;
