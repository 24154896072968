// src/pages/ProjectDetails/FitMyJob.jsx

import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import BatteryProgressBar from '../../components/BatteryProgressBar';
import ExtractedSkillsList from '../../components/ExtractedSkillsList';
import JobItem from '../../components/JobItem'; // Ensure the path is correct
import Modal from '../../components/Modal';
import SuggestedJobTitles from '../../components/SuggestedJobTitles';
import './FitMyJob.css'; // Additional styling

function FitMyJob() {
  // State Variables
  const [jobListings, setJobListings] = useState([]); // Job listings state
  const [uploading, setUploading] = useState(false); // Loading state for resume upload
  const [fetching, setFetching] = useState(false); // Loading state for job fetching
  const [cacheKey, setCacheKey] = useState(""); // Cache key for polling
  const [suggestedJobTitles, setSuggestedJobTitles] = useState([]); // Suggested job titles state
  const [selectedJobTitle, setSelectedJobTitle] = useState(""); // Selected job title
  const [extractedSkills, setExtractedSkills] = useState([]); // Extracted skills state
  const [error, setError] = useState(null); // Error handling
  const [success, setSuccess] = useState(null); // Success message

  // Progress States
  const [uploadProgress, setUploadProgress] = useState(0); // Upload progress percentage

  // Pagination State
  const [currentPage, setCurrentPage] = useState(1);
  const jobsPerPage = 10;

  // Modal and Preview States
  const [selectedJob, setSelectedJob] = useState(null); // Job selected for preview
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility
  const [previewMatchingScore, setPreviewMatchingScore] = useState(null); // Matching score in preview
  const [previewLoading, setPreviewLoading] = useState(false); // Loading state for preview
  const [previewError, setPreviewError] = useState(null); // Error state for preview

  // Cache for Matching Scores to Prevent Redundant API Calls
  const [matchingScoreCache, setMatchingScoreCache] = useState({});
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  // Refs for Focus Management
  const triggerButtonRef = useRef(null); // Reference to the "Find Jobs" button

  // Handle Focus When Modal Opens/Closes
  useEffect(() => {
    if (isModalOpen) {
      // Prevent background scrolling
      document.body.style.overflow = 'hidden';
    } else {
      // Restore background scrolling
      document.body.style.overflow = 'auto';
    }
  }, [isModalOpen]);

  // Handle File Upload
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log("Selected file:", file);
      setUploading(true);
      setUploadProgress(0); // Reset upload progress
      setError(null); // Reset error state
      setSuccess(null); // Reset success message
      const formData = new FormData();
      formData.append("file", file);

      
      const xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
        `${apiBaseUrl}/api/projects/fit-my-job/upload-resume`);

      // Update Upload Progress
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentComplete = Math.round((event.loaded / event.total) * 100);
          setUploadProgress(percentComplete);
          console.log(`Upload Progress: ${percentComplete}%`);
        }
      };

      // Handle Response
      xhr.onload = () => {
        setUploading(false);
        console.log("Upload Response Status:", xhr.status);
        console.log("Upload Response Text:", xhr.responseText);
        if (xhr.status >= 200 && xhr.status < 300) {
          try {
            const data = JSON.parse(xhr.responseText);
            console.log("API Response:", data);
            setCacheKey(data.cache_key || "");
            setSuggestedJobTitles(data.suggested_job_titles || []);
            setExtractedSkills(data.extracted_skills || []);
            setSelectedJobTitle(data.suggested_job_titles ? data.suggested_job_titles[0] : ""); // Default to the first title
            // Remove the following line to prevent automatic job listing display upon upload
            // setJobListings(data.job_listings || []);
            setSuccess("Resume uploaded successfully!");
            console.log("cache_key set to:", data.cache_key);
          } catch (parseError) {
            console.error("Error parsing upload response:", parseError);
            setError("Invalid response from server. Please try again.");
          }
        } else {
          console.error("Error uploading resume:", xhr.responseText);
          setError("Error uploading resume. Please try again.");
        }
      };

      // Handle Errors
      xhr.onerror = () => {
        setUploading(false);
        console.error("XHR Error during resume upload.");
        setError("Error uploading resume. Please try again.");
      };

      xhr.send(formData);
    }
  };

  // Handle Job Title Selection
  const handleJobTitleSelect = (title) => {
    setSelectedJobTitle(title);
    setSuccess(null); // Reset success message
    setError(null); // Reset error state
  
  };
  

  // Handle Skill Selection
  const handleSkillSelect = (skill) => {
    // console.log("Selected Skill:", skill);
    // Implement further filtering based on skills here if needed
  };

  // Start Fetching Jobs
  const startFetching = async () => {
    console.log("Starting to fetch jobs with cacheKey:", cacheKey, "and selectedJobTitle:", selectedJobTitle);
    
    if (selectedJobTitle && cacheKey) {
      setJobListings([]); // Clear previous job listings before fetching new ones
      setFetching(true);
      setError(null); // Reset error state
      setSuccess(null); // Reset success message
  
      try {
        const res = await fetch(
          `${apiBaseUrl}/api/projects/fit-my-job/start-fetching`,
          {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: new URLSearchParams({
              cache_key: cacheKey,
              selected_job_title: selectedJobTitle,  // Use the selected job title for the fetch
            }),
          }
        );
  
        console.log("Start Fetching Response Status:", res.status);
        const data = await res.json();
        console.log("Start Fetching Response Data:", data);
  
        if (!res.ok) {
          throw new Error(data.detail || "Failed to start fetching jobs");
        }
  
        setSuccess(`Job fetching started for "${selectedJobTitle}"!`);
        pollJobResults();  // Continue to poll the results
      } catch (err) {
        console.error("Error in startFetching:", err);
        setError("Error starting job fetching. Please try again.");
        setFetching(false);
      }
    } else {
      console.warn("startFetching called without selectedJobTitle or cacheKey.");
      setError("Please upload your resume and select a job title before fetching jobs.");
    }
  };
  
  

  // Polling Function to Fetch Job Results Periodically
  const pollJobResults = () => {
    //console.log("Starting polling for job results...");
    const interval = setInterval(async () => {
      try {
        //console.log("Polling for job results with cacheKey:", cacheKey);
        const res = await fetch(
          `${apiBaseUrl}/api/projects/fit-my-job/check-job-results/${cacheKey}`
        );
        //console.log("Poll Response Status:", res.status);
        const data = await res.json();
        // console.log("Poll Response Data:", data);

        if (res.ok && data.job_listings && Array.isArray(data.job_listings) && data.job_listings.length > 0) {
          // console.log("Job listings received:", data.job_listings);
          setJobListings(data.job_listings);
          setFetching(false);
          setSuccess("Job listings fetched successfully!");
          clearInterval(interval);
        } else if (!res.ok) {
          throw new Error(data.detail || "Failed to fetch job results.");
        } else {
          console.log("No job listings available yet. Continuing to poll...");
        }
      } catch (err) {
        console.error("Error fetching job results:", err);
        setError("Error fetching job results.");
        setFetching(false);
        clearInterval(interval);
      }
    }, 3000); // Poll every 3 seconds
  };

  // Handle Page Change
  const paginate = (pageNumber) => {
    console.log("Paginating to page:", pageNumber);
    setCurrentPage(pageNumber);
  };

  // Remove Sorting and Filtering Functions and UI Elements
  // Sorting and Filtering are no longer needed since matching scores are fetched individually

  // Calculate Total Pages
  const totalPages = Math.ceil(jobListings.length / jobsPerPage);
  console.log("Total Pages Calculated:", totalPages);

  // Get Current Jobs
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = jobListings.slice(indexOfFirstJob, indexOfLastJob);
  console.log("Current Jobs for Page", currentPage, ":", currentJobs);

  // Handle Preview Button Click
  const handlePreviewClick = async (job) => {
    console.log("Previewing job:", job);
    setSelectedJob(job);
    setIsModalOpen(true);
    setPreviewError(null);

    if (matchingScoreCache[job.link]) {
      console.log("Matching score fetched from cache:", matchingScoreCache[job.link]);
      setPreviewMatchingScore(matchingScoreCache[job.link]);
      return;
    }

    setPreviewMatchingScore(null);
    setPreviewLoading(true);

    try {
      const res = await fetch(
        `${apiBaseUrl}/api/projects/fit-my-job/get-matching-score`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            cache_key: cacheKey,
            job_description: job.description,
          }),
        }
      );

      console.log("Get Matching Score Response Status:", res.status);
      const data = await res.json();
      console.log("Get Matching Score Response Data:", data);

      if (!res.ok) {
        throw new Error(data.detail || "Failed to fetch matching score.");
      }

      setPreviewMatchingScore(data.matching_score);
      setMatchingScoreCache((prev) => ({ ...prev, [job.link]: data.matching_score }));
    } catch (err) {
      console.error("Error fetching matching score:", err);
      setPreviewError("Error fetching matching score. Please try again.");
    } finally {
      setPreviewLoading(false);
    }
  };

  // Render Job List with Animation Using JobItem
  const JobList = () => (
    <ul className="mt-10 list-none">
      {currentJobs.length > 0 ? (
        currentJobs.map((job, index) => (
          <motion.li
            key={index}
            className="flex flex-col"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
          >
            <JobItem job={job} onPreview={handlePreviewClick} />
          </motion.li>
        ))
      ) : (
        <p className="text-center text-gray-400">
          No job listings found.
        </p>
      )}
    </ul>
  );

  // Render Pagination Controls
  const Pagination = () => (
    <div className="flex justify-center mt-6">
      <nav>
        <ul className="inline-flex -space-x-px list-none">
          {Array.from({ length: totalPages }, (_, index) => (
            <li key={index}>
              <button
                onClick={() => paginate(index + 1)}
                className={`px-3 py-2 leading-tight ${
                  currentPage === index + 1
                    ? "text-white bg-blue-500"
                    : "text-gray-500 bg-gray-700 dark:bg-gray-800 dark:text-gray-400"
                } border border-gray-300`}
              >
                {index + 1}
              </button>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );

  return (
    <div className="min-h-screen p-10 bg-gray-900"> {/* Dark mode background */}
      <h2 className="text-4xl font-bold mb-6 text-center text-gray-300">
        Fit My Job
      </h2>

      {/* File Input for Resume Upload */}
      <div className="file-input flex flex-col items-center">
        <input
          type="file"
          accept="application/pdf"
          onChange={handleFileChange}
          className="border p-4 rounded-lg mb-4 bg-gray-700 text-gray-300"
        />
      </div>

      {/* Show Upload Progress */}
      {uploading && (
        <div className="flex flex-col items-center mt-10">
          <BatteryProgressBar percentage={uploadProgress} size="small" />
          <span className="mt-2 text-xl text-blue-500">Uploading resume... {uploadProgress}%</span>
        </div>
      )}

      {/* Display Success Message */}
      {success && (
        <div className="mt-4 text-green-500 text-center">
          <p>{success}</p>
        </div>
      )}

      {/* Display Error Message */}
      {error && (
        <div className="mt-4 text-red-500 text-center">
          <p>{error}</p>
        </div>
      )}

      {/* Display Suggested Job Titles and Extracted Skills Side by Side */}
      {!uploading && suggestedJobTitles.length > 0 && (
        <div className="flex flex-col md:flex-row md:space-x-6">
          {/* Suggested Job Titles */}
          <div className="md:w-1/2">
            <SuggestedJobTitles
              titles={suggestedJobTitles}
              selectedTitle={selectedJobTitle}
              onSelect={handleJobTitleSelect}
            />
          </div>

          {/* Extracted Skills */}
          <div className="md:w-1/2">
            <ExtractedSkillsList skills={extractedSkills} onSelect={handleSkillSelect} />
          </div>
        </div>
      )}

      {/* Button to Start Fetching Jobs */}
      {selectedJobTitle && !uploading && (
        <div className="flex justify-center mt-6">
          <button
            onClick={startFetching}
            disabled={fetching}
            ref={triggerButtonRef} // Assign ref to trigger button
            className={`bg-blue-500 hover:bg-blue-700 text-white px-6 py-2 rounded-lg ${fetching ? 'opacity-50 cursor-not-allowed' : ''
            }`}>
            {fetching ? "Fetching Jobs..." : "Find Jobs"}
          </button>
        </div>
      )}

      {/* Show Fetching Indicator */}
      {fetching && (
        <div className="flex flex-col items-center mt-10">
          <span className="loader"></span> {/* Loader CSS defined below */}
          <span className="mt-2 text-xl text-blue-500">Fetching jobs... Please wait.</span>
        </div>
      )}

      {/* Render Job List */}
      {!fetching && jobListings.length > 0 && (
        <>
          <JobList />
          <Pagination />
        </>
      )}

      {/* Modal for Preview */}
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={`${selectedJob?.title} at ${selectedJob?.company}`}
        description={selectedJob?.description || "No description provided."}
        matchingScore={previewMatchingScore !== null ? `${previewMatchingScore}` : "N/A"}
      />
    </div> 
  );
}

export default FitMyJob;
