// src/pages/Contact.jsx
import { motion } from "framer-motion";
import React from "react";
import {
  AiOutlineGithub,
  AiOutlineLinkedin,
  AiOutlineMail,
} from "react-icons/ai"; // Icons

function Contact({ setShowNavbar, setActiveTab }) {
  React.useEffect(() => {
    setShowNavbar(true);
    setActiveTab("contact");
    window.scrollTo(0, 0); // Scroll to the top when switching tabs
  }, [setShowNavbar, setActiveTab]);

  return (
    <div className="min-h-screen bg-transparent text-white flex flex-col justify-center items-center z-2">
      {/* Title */}
      <motion.div
        className="text-center mb-12 z-2"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <h1 className="text-4xl md:text-6xl font-bold">Contact Me</h1>
        <p className="text-xl mt-2">Let's get in touch</p>
      </motion.div>

      {/* Contact Info */}
      <motion.div
        className="text-center mb-12 space-y-6 z-2"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.5 }}
      >
        <p className="flex items-center justify-center">
          <AiOutlineMail className="mr-2 text-green-400" size={24} />
          sina.behdadkia@outlook.com
        </p>
      </motion.div>

      {/* Social Icons */}
      <motion.div
        className="flex space-x-8 text-3xl mb-12"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 2 }}
      >
        <a
          href="https://github.com/sbehdadk"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiOutlineGithub className="hover:text-green-400 transition duration-300" />
        </a>
        <a
          href="https://www.linkedin.com/in/sina-behdadkia"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiOutlineLinkedin className="hover:text-green-400 transition duration-300" />
        </a>
      </motion.div>

      {/* Footer Icons can be repeated here as well */}
    </div>
  );
}

export default Contact;
