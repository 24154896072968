import React from "react";
import {
  AiOutlineGithub,
  AiOutlineLinkedin,
  AiOutlineMail,
} from "react-icons/ai";

function Footer() {
  return (
    <footer className="relative bottom-0 left-0 w-full bg-black text-white py-3 text-center mt-8">
      <div className="flex justify-center space-x-4 text-lg">
        <a
          href="https://github.com/sbehdadk"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiOutlineGithub className="hover:text-green-400 transition duration-300" />
        </a>
        <a
          href="https://www.linkedin.com/in/sina-behdadkia"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiOutlineLinkedin className="hover:text-green-400 transition duration-300" />
        </a>
        <a
          href="mailto:sina.behdadkia@outlook.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiOutlineMail className="hover:text-green-400 transition duration-300" />
        </a>
      </div>
    </footer>
  );
}

export default Footer;
