import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function Navbar({ activeTab, setActiveTab }) {
  const location = useLocation();
  const navigate = useNavigate();

  // Automatically set the active tab based on the URL path
  useEffect(() => {
    const currentPath = location.pathname.substring(1);
    setActiveTab(currentPath ? currentPath : "home");
  }, [location, setActiveTab]);

  return (
    <nav className="navbar flex justify-between items-center p-4">
      {/* Left side with your name */}
      <div className="text-xl font-bold text-white mr-auto">Sina Behdadkia</div>

      {/* Right side with navigation items */}
      <div className="flex space-x-8">
        <div
          className={`nav-item ${activeTab === "home" ? "active" : ""}`}
          onClick={() => {
            navigate("/"); // Navigate to Home
            setActiveTab("home"); // Update active tab
          }}
        >
          Home
        </div>
        <div
          className={`nav-item ${activeTab === "resume" ? "active" : ""}`}
          onClick={() => {
            navigate("/resume"); // Navigate to Resume
            setActiveTab("resume"); // Update active tab
          }}
        >
          Résumé
        </div>
        <div
          className={`nav-item ${activeTab === "projects" ? "active" : ""}`}
          onClick={() => {
            navigate("/projects"); // Navigate to Projects
            setActiveTab("projects"); // Update active tab
          }}
        >
          Projects
        </div>
        <div
          className={`nav-item ${activeTab === "contact" ? "active" : ""}`}
          onClick={() => {
            navigate("/contact"); // Navigate to Contact
            setActiveTab("contact"); // Update active tab
          }}
        >
          Contact
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
