// src/components/Modal.jsx

import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";

function Modal({ isOpen, onClose, title, description, matchingScore }) {
  const closeButtonRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      console.log("Modal opened");
      closeButtonRef.current?.focus();
      document.body.style.overflow = 'hidden';
    } else {
      console.log("Modal closed");
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  if (!isOpen) return null;

  // Create portal root if it doesn't exist
  const modalRoot = document.getElementById("modal-root") || (() => {
    const root = document.createElement("div");
    root.setAttribute("id", "modal-root");
    document.body.appendChild(root);
    return root;
  })();

  return ReactDOM.createPortal(
    <div
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-title"
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
    >
      <div className="bg-gray-800 text-white p-6 rounded-lg w-11/12 md:w-2/3 lg:w-1/2 max-h-[80vh] overflow-y-auto overflow-x-auto">
        <div className="flex justify-between items-center mb-4">
          <h3 id="modal-title" className="text-xl font-bold">
            {title}
          </h3>
          <button
            onClick={() => {
              console.log("Modal close button clicked");
              onClose();
            }}
            className="text-red-500 hover:text-red-700 text-2xl"
            aria-label="Close Modal"
            ref={closeButtonRef}
          >
            &times;
          </button>
        </div>
        <div className="mb-4">
          <h4 className="font-semibold">Job Description:</h4>
          <p
            className="text-sm whitespace-pre-wrap break-all w-full"
            style={{ wordBreak: "break-all" }} // Inline style as a fallback
          >
            {description}
          </p>
        </div>
        <div>
          <h4 className="font-semibold">Matching Score:</h4>
          {matchingScore !== "N/A" && <p>{matchingScore}%</p>}
          {matchingScore === "N/A" && <p>N/A</p>}
        </div>
      </div>
    </div>,
    modalRoot
  );
}

export default Modal;
