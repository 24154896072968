// src/pages/Projects.jsx
import { motion } from "framer-motion";
import React from "react";
import { Link } from "react-router-dom";

function Projects({ setShowNavbar, setActiveTab }) {
  React.useEffect(() => {
    setShowNavbar(true);
    setActiveTab("projects");
    window.scrollTo(0, 0); // Scroll to the top when switching tabs
  }, [setShowNavbar, setActiveTab]);
  const chatbotImage = `${process.env.PUBLIC_URL}/chatbot.jpg`;
  const scorePredictorImage = `${process.env.PUBLIC_URL}/score_predictor.jpg`;

  // Placeholder data for projects
  const projects = [
    {
      id: 1,
      title: "Find my job",
      description: "An assistant to find resume match & job tracker.",
      img: chatbotImage, // Placeholder image URL
      link: "/projects/fit-my-job",
    },
    {
      id: 2,
      title: "Score-predictor",
      description:
        "A student score predictor based on previous information of the class.",
      img: scorePredictorImage,
      link: "/projects/score-predictor",
    },
  ];

  return (
    <motion.section
      className="min-h-screen flex flex-col pt-20 projects-section p-8 bg-transparent text-white"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
    >
      <h2 className="pt-20 text-4xl font-bold mb-8">Projects</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 lg:grid-cols-3">
        {projects.map((project) => (
          <div
            key={project.id}
            className="p-6 rounded-lg shadow-lg text-center"
          >
            <img
              src={project.img}
              alt={project.title}
              className="rounded-full mx-auto mb-4 w-24 h-24 object-cover"
            />
            <h3 className="text-xl font-bold mb-2">{project.title}</h3>
            <p className="mb-4">{project.description}</p>
            <Link
              to={project.link}
              className="bg-green-500 text-black py-2 px-4 rounded-full hover:bg-green-600 transition"
            >
              Try it out!
            </Link>
          </div>
        ))}
      </div>
    </motion.section>
  );
}

export default Projects;
