// src/components/SuggestedJobTitles.jsx

import PropTypes from 'prop-types';
import React from 'react';
import './SuggestedJobTitles.css';

const SuggestedJobTitles = ({ titles, selectedTitle, onSelect }) => {
  if (!Array.isArray(titles) || titles.length === 0) {
    return (
      <div className="suggested-job-titles">
        <h3 className="title">Suggested Job Titles</h3>
        <p className="no-titles">No job titles suggested.</p>
      </div>
    );
  }

  return (
    <div className="suggested-job-titles">
      <h3 className="title">Suggested Job Titles</h3>
      <ul className="titles-list">
        {titles.map((title, index) => (
          <li
            key={index}
            className={`title-item ${selectedTitle === title ? 'selected' : ''}`}
            onClick={() => onSelect(title)}
            role="button"
            tabIndex={0}
            aria-pressed={selectedTitle === title}
            onKeyPress={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                onSelect(title);
              }
            }}
          >
            {title}
          </li>
        ))}
      </ul>
    </div>
  );
};

SuggestedJobTitles.propTypes = {
  titles: PropTypes.arrayOf(PropTypes.string),
  selectedTitle: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
};

export default SuggestedJobTitles;
