// src/components/ExtractedSkillsList.jsx

import PropTypes from 'prop-types';
import React from 'react';
import './ExtractedSkillsList.css';

const ExtractedSkillsList = ({ skills = [], onSelect }) => {
  if (!Array.isArray(skills) || skills.length === 0) {
    return (
      <div className="extracted-skills">
        <h3 className="title">Extracted Skills</h3>
        <p className="no-skills">No skills extracted.</p>
      </div>
    );
  }

  return (
    <div className="extracted-skills">
      <h3 className="title">Extracted Skills</h3>
      <ul className="skills-list">
        {skills.map((skill, index) => (
          <li
            key={index}
            className="skill-item"
            onClick={() => onSelect(skill)}
            role="button"
            tabIndex={0}
            aria-pressed="false"
            onKeyPress={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                onSelect(skill);
              }
            }}
          >
            {skill}
          </li>
        ))}
      </ul>
    </div>
  );
};

ExtractedSkillsList.propTypes = {
  skills: PropTypes.arrayOf(PropTypes.string),
  onSelect: PropTypes.func.isRequired,
};



export default ExtractedSkillsList;
